<template>
  <div>
    <el-dialog
      title="转换预算类型"
      :visible="editBuygetTypeShow"
      width="1400px"
      @close="close"
      @opened="openInit"
    >
      <div v-loading="loading">
        <el-form
          :model="adInfo"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
          label-suffix=":"
        >
          <el-form-item
            label="广告系列预算优化"
            prop="name"
          >
            <el-switch
              v-model="adInfo.is_cbo"
              disabled
            ></el-switch>
            <p class="tips">
              {{ adInfo.is_cbo ? testList[0] : testList[1] }}
            </p>
          </el-form-item>
        </el-form>
        <el-form
          :model="adSet"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
          v-if="adInfo.is_cbo"
        >
          <el-form-item
            label="广告系列预算"
            class="blod"
          >
            <el-input v-model="adSet.budget">
              <el-select
                v-model="adSet.budget_type"
                slot="prepend"
                placeholder="请选择"
                disabled
              >
                <el-option
                  label="单日预算"
                  value="daily"
                ></el-option>
                <el-option
                  label="总预算"
                  value="lifetime"
                ></el-option>
              </el-select>
              <el-button slot="append">{{ currencyName }}</el-button>
            </el-input>
            <p
              class="tips"
              style="margin-top: 10px"
            >
              每日花费的实际金额可能不同
            </p>
          </el-form-item>
          <el-form-item
            label="优化目标"
            class="blod"
          >
            <el-select
              v-model="adSet.optimization_goal"
              placeholder=""
              @change="selectGoal"
              disabled
            >
              <el-option
                v-for="item in optimizationGoals"
                :key="item.key"
                :label="item.desc"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="竞价策略">
            <el-radio-group
              v-model="adSet.bidStrategy"
              @change="selectBid"
            >
              <el-radio
                style="margin-right: 10px"
                :label="item.key"
                v-for="(item, i) in bidStrategyList(adSet.optimization_goal, this.row.smart_promotion_type)"
                :key="i"
                >{{ item.desc }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="adset">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            border
            max-height="280px"
          >
            <el-table-column
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column label="广告组名称">
              <template slot-scope="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="spend"
              label="花费金额"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="budget"
              label="预算"
              width="260"
              v-if="!adInfo.is_cbo"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.budget"
                  @input="upadteBudget(scope.row, scope.row.budget, scope.$index, 'budget')"
                >
                  <el-select
                    v-model="scope.row.budget_type"
                    slot="prepend"
                    placeholder="请选择"
                    disabled
                  >
                    <el-option
                      label="单日预算"
                      value="daily"
                    ></el-option>
                    <el-option
                      label="总预算"
                      value="lifetime"
                    ></el-option>
                  </el-select>
                  <el-button slot="append">{{ currencyName }}</el-button>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="optimization_goal"
              label="优化目标"
              width="200px"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.optimization_goal"
                  placeholder=""
                  disabled
                >
                  <el-option
                    v-for="item in optimizationGoals"
                    :key="item.key"
                    :label="item.desc"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="bidStrategy"
              label="竞价策略"
              width="300px"
            >
              <template slot-scope="scope">
                <el-radio-group
                  v-model="scope.row.bidStrategy"
                  :disabled="adInfo.is_cbo"
                  @change="upadteBudget(scope.row, scope.row.bidStrategy, scope.$index, 'bidStrategy')"
                >
                  <el-radio
                    style="margin-right: 10px"
                    :label="item.key"
                    v-for="(item, i) in bidStrategyList(scope.row.optimization_goal, scope.row.smart_promotion_type)"
                    :key="i"
                    >{{ item.desc }}</el-radio
                  >
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column
              prop="bidAmount"
              label="竞价金额"
              width="160"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.bidAmount"
                  @input="upadteBudget(scope.row, scope.row.bidAmount, scope.$index, 'bidAmount')"
                  v-if="scope.row.bidStrategy == 'COST_CAP' || scope.row.bidStrategy == 'LOWEST_COST_WITH_BID_CAP'"
                >
                  <el-button slot="append">{{ currencyName }}</el-button>
                </el-input>
                <el-input
                  v-model="scope.row.roas_average_floor"
                  @input="upadteBudget(scope.row, scope.row.roas_average_floor, scope.$index, 'roas_average_floor')"
                  v-if="scope.row.bidStrategy == 'LOWEST_COST_WITH_MIN_ROAS'"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <p>已发布广告组的单日总预算：{{ currencyName }}{{ activeMoney.activeMoney }}</p>
            <p>已关闭广告组的单日总预算：{{ currencyName }}{{ activeMoney.pausedMoney }}</p>
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancle">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { report, budgetOptimization } from '@/api/adManagement';
import { mapState, mapGetters } from 'vuex';
export default {
  props: ['editBuygetTypeShow', 'row', 'is_cbo', 'textList'],
  data() {
    return {
      adInfo: {
        is_cbo: false,
      },
      testList: [],
      adSet: {
        budget: 10,
        budget_type: 'daily',
        optimization_goal: '',
        bidStrategy: '',
      },
      tableData: [],
      params: {
        account_id: this.row.rowAccountId || this.$store.getters.accountId,
        action_attribution_windows: [],
        after: '',
        date_end: '',
        date_start: '',
        fields: [
          'budget',
          'id',
          'name',
          'status',
          'spend',
          'bid_amount',
          'bid_strategy',
          'objective',
          'optimization_goal',
          'roas_average_floor',
          'smart_promotion_type',
        ],
        filtering: [],
        object_filtering: [{ field: 'campaign.id', operator: 'IN', value: [this.row.id] }],
        keyword: '',
        level: 'adset',
        page_length: 100,
        sort: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('initData', ['getBidStrategyList', 'getOptimizationGoals']),
    ...mapState('initData', ['initData']),
    currencyName() {
      return this.$store.getters?.currentUiUnit?.symbol || '$';
    },
    showMoney(v) {
      return this.$store.getters.toFormatedMoney(v).formated;
    },
    activeMoney() {
      let budget1 = 0,
        budget2 = 0;
      this.tableData.map((item) => {
        if (item.status == 'ACTIVE') {
          budget1 += item.budget / 1;
        } else if (item.status == 'PAUSED') {
          budget2 += item.budget / 1;
        }
      });
      return {
        activeMoney: budget1,
        pausedMoney: budget2,
      };
    },

    // objectiveList() {
    //   let list = [];
    //   if (this.objective) {
    //     this.initData.ad.CampaignBidStrategy.map((item) => {
    //       item.objectives.map((objective) => {
    //         if (objective.key == this.objective) {
    //           list.push(item);
    //         }
    //       });
    //     });
    //   }
    //   return list;
    // },
    optimizationGoals() {
      return this.getOptimizationGoals(this.objective);
    },
    objective() {
      return this.row.objective;
    },
  },
  methods: {
    bidStrategyList(optimization_goal, smart_promotion_type) {
      return this.getBidStrategyList({
        is_cbo: this.is_cbo,
        objective: this.objective,
        optimization_goal: optimization_goal,
        smart_promotion_type: smart_promotion_type,
      });
    },

    upadteBudget(row, v, index, key) {
      console.log(row, key, v);
      this.$set(row, key, v);
      this.$set(this.tableData, index, row);
      this.$forceUpdate();
    },
    openInit() {
      console.log(1, this.row);
      this.adInfo.is_cbo = this.is_cbo;
      this.testList = this.textList;
      if (localStorage.getItem('adDateRange')) {
        this.dateRange = { ...JSON.parse(localStorage.getItem('adDateRange')) };
      }
      if (this.row.daily_budget) {
        this.adSet.budget_type = 'daily';
        this.adSet.budget = this.$store.getters.toFormatedMoney(this.row.daily_budget).value;
      } else if (this.row.lifetime_budget) {
        this.adSet.budget_type = 'lifetime';
        this.adSet.budget = this.$store.getters.toFormatedMoney(this.row.lifetime_budget).value;
      }

      this.setDateRangeParam();
    },
    close() {
      this.$emit('update:editBuygetTypeShow', false);
      this.$emit('update:is_cbo', !this.is_cbo);
    },
    cancle() {
      this.$emit('update:editBuygetTypeShow', false);
    },
    // 验证table
    valideTable(params) {
      console.log('aaaaaaaaaaaaaaaaaaa');
      let flag = true;
      let type = this.adSet.budget_type == 'daily' ? 'dailyBudget' : 'lifetimeBudget';
      let isDailyBudget = this.adSet.budget_type == 'daily' ? true : false;
      params.campaignBudget.isDailyBudget = isDailyBudget;
      if (this.is_cbo) {
        params.campaignBudget = { ...params.campaignBudget, ...this.adSet };
        params.campaignBudget[`${this.adSet.budget_type}Budget`] = this.$store.getters.toBaseMoney(this.adSet.budget);
      }
      this.tableData.map((v) => {
        if (this.adInfo.is_cbo) {
          if (this.adSet.budget < 4) {
            this.$message({
              type: 'warning',
              message: '预算至少为$4',
            });
            return false;
          }
        } else {
          if (v.budget < 1) {
            this.$message({
              type: 'warning',
              message: '预算至少为$1',
            });
            flag = false;
          }
        }
        if ((v.bidStrategy == 'COST_CAP' || v.bidStrategy == 'LOWEST_COST_WITH_BID_CAP') && !v.bidAmount) {
          this.$message({
            type: 'warning',
            message: '请设置竞价金额',
          });
          flag = false;
        }
        if (v.bidStrategy == 'LOWEST_COST_WITH_MIN_ROAS' && !v.roas_average_floor) {
          this.$message({
            type: 'warning',
            message: '请设置最小roas',
          });
          flag = false;
        }
        let obj = {};
        obj[type] = this.$store.getters.toBaseMoney(v.budget);
        obj.isDailyBudget = isDailyBudget;
        obj.adSetId = v.id;
        obj.bidStrategy = v.bidStrategy;
        obj.bidAmount =
          v.bidStrategy == 'LOWEST_COST_WITHOUT_CAP'
            ? null
            : v.bidAmount
            ? this.$store.getters.toBaseMoney(v.bidAmount)
            : null;
        console.log(v.bidAmount, 'v.bidAmountv.bidAmountv.bidAmount');
        obj.roas_average_floor = v.roas_average_floor / 1;
        params.adSetBudgets.push({ ...obj });
      });
      return {
        flag: flag,
        params: params,
      };
    },
    sure() {
      // this.close()
      // 广告组转广告系列预算
      let params = {
        isCampaign2AdSet: !this.adInfo.is_cbo,
        campaignBudget: {
          campaignId: this.row.id,
        },
        adSetBudgets: [],
      };
      let msg = this.valideTable(params);
      if (!msg.flag) return false;
      this.$showLoading();
      budgetOptimization(msg.params, this.row.rowAccountId).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.comment,
          });
          let data = {};
          data[`${this.adSet.budget_type}_budget`] = this.adSet.budget;
          this.$emit('refreshData', data);
          this.$emit('update:editBuygetTypeShow', false);
          this.$nextTick(() => {
            this.$emit('update.is_cbo', this.is_cbo);
          });
        }
      });
      console.log(params);
    },
    handleSelectionChange() {},
    getList() {
      if (this.row.id && this.editBuygetTypeShow) {
        this.loading = true;
        report(JSON.stringify(this.params), this, this.row.rowThirdNum).then((res) => {
          this.loading = false;
          this.tableData = [];
          if (res.code == 0) {
            let data = res.data.data;
            data.forEach((item) => {
              item.budget = this.is_cbo ? null : (this.adSet.budget / data.length).toFixed(2);
              item.budget_type = this.is_cbo ? (item.daily_budget ? 'daily' : 'lifetime') : this.adSet.budget_type;
              item.spend = item.spend ? this.currencyName + '' + item.spend : '--';
              item.bidStrategy = item.bid_strategy || 'LOWEST_COST_WITHOUT_CAP';
              item.bidAmount = item.bid_amount ? this.$store.getters.toFormatedMoney(item.bid_amount).value : null;
              this.tableData.push(item);
            });
            if (this.is_cbo) {
              let goas = new Set(data.map((v) => v.optimization_goal));
              console.log(goas);
              if (goas.size > 1 || data[0].smart_promotion_type == 'AUTOMATED_SHOPPING_ADS') {
                this.$confirm(
                  `${goas.size > 1 ? '广告组的优化目标不一致，暂不支持转系列预算' : '智能购物广告不支持系列预算'}`,
                  '提示',
                  {
                    type: 'warning',
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                  },
                ).then((res) => {
                  this.cancle();
                });
              }
              this.adSet.bidStrategy = data[0].bid_strategy;
              this.adSet.optimization_goal = data[0].optimization_goal;
              this.adSet.budget_type = data[0].budget_type;
            }
          }
          this.$forceUpdate();
        });
      }
    },
    setDateRangeParam() {
      // console.log('dateRange',this.dateRange);
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      this.getList();
    },
    // 选择系列竞价策略
    selectGoal(v) {
      this.adSet.bidStrategy = 'LOWEST_COST_WITHOUT_CAP';
      this.tableData.forEach((item) => {
        item.optimization_goal = v;
        item.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
      });
    },
    // 改变系列竞价策略
    selectBid(v) {
      this.tableData.forEach((item) => {
        item.bidStrategy = v;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input-group__prepend {
  width: 110px;
}
.el-form {
  .el-input-group {
    width: 80%;
  }
}
.tips {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
}
</style>
